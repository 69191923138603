import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64')
];

export const server_loads = [2,14,10];

export const dictionary = {
		"/(public)": [48,[9]],
		"/(protected)/(user)/account/(menu)": [20,[2,6],[3]],
		"/(protected)/(user)/account/api": [33,[2],[3]],
		"/(protected)/(user)/account/(menu)/billing": [~21,[2,6],[3]],
		"/(protected)/(user)/account/(menu)/billing/manage": [~22,[2,6],[3]],
		"/(protected)/(user)/account/create_profile": [34,[2],[3]],
		"/(protected)/(user)/account/(menu)/media": [~23,[2,6],[3]],
		"/(protected)/(user)/account/select_plan": [35,[2],[3]],
		"/(protected)/(user)/account/(menu)/settings": [24,[2,6],[3]],
		"/(protected)/(user)/account/(menu)/settings/change_email_subscription": [26,[2,6],[3]],
		"/(protected)/(user)/account/(menu)/settings/change_email": [25,[2,6],[3]],
		"/(protected)/(user)/account/(menu)/settings/change_password": [27,[2,6],[3]],
		"/(protected)/(user)/account/(menu)/settings/delete_account": [28,[2,6],[3]],
		"/(protected)/(user)/account/(menu)/settings/edit_profile": [29,[2,6],[3]],
		"/(protected)/(user)/account/(menu)/settings/edit_socials": [30,[2,6],[3]],
		"/(protected)/(user)/account/(menu)/settings/reset_password": [31,[2,6],[3]],
		"/(protected)/(user)/account/sign_out": [36,[2],[3]],
		"/(protected)/(user)/account/(menu)/studio": [32,[2,6],[3]],
		"/(protected)/(user)/account/subscribe/[slug]": [~37,[2],[3]],
		"/(protected)/(admin)/admin": [~15,[2,4],[3,5]],
		"/(protected)/(admin)/admin/settings": [16,[2,4],[3,5]],
		"/(protected)/(admin)/admin/users": [~17,[2,4],[3,5]],
		"/(protected)/(admin)/admin/users/[user_id]": [~18,[2,4],[3,5]],
		"/(protected)/(admin)/admin/waitinglist": [~19,[2,4],[3,5]],
		"/(protected)/(user)/app": [38,[2,7],[3]],
		"/(protected)/(user)/app/post/[media_id]/facebook": [~39,[2,7],[3]],
		"/(protected)/(user)/app/post/[media_id]/instagram": [~40,[2,7],[3]],
		"/(public)/blog": [57,[9,12]],
		"/(public)/blog/(posts)/awesome_post": [58,[9,12,13]],
		"/(public)/blog/(posts)/example_blog_post": [59,[9,12,13]],
		"/(public)/blog/(posts)/how_we_built_our_41kb_saas_website": [60,[9,12,13]],
		"/(public)/contact_us": [61,[9]],
		"/(public)/join": [62,[9,14]],
		"/(public)/(auth)/login": [~49,[9,10]],
		"/(public)/(auth)/login/current_password_error": [50,[9,10]],
		"/(public)/(auth)/login/forgot_password": [~51,[9,10]],
		"/(public)/(auth)/login/sign_in": [52,[9,10]],
		"/(public)/(auth)/login/sign_up": [~53,[9,10]],
		"/(protected)/(user)/onboarding": [~41,[2,8],[3]],
		"/(protected)/(user)/onboarding/(30 - basic)/basic": [~43,[2,8],[3]],
		"/(protected)/(user)/onboarding/(90 - done)/done": [~47,[2,8],[3]],
		"/(protected)/(user)/onboarding/(80 - interests)/interests": [~46,[2,8],[3]],
		"/(protected)/(user)/onboarding/(70 - socials)/socials": [~45,[2,8],[3]],
		"/(protected)/(user)/onboarding/(50 - user type)/usertype": [~44,[2,8],[3]],
		"/(protected)/(user)/onboarding/(00 - welcome)/welcome": [42,[2,8],[3]],
		"/(public)/pricing": [63,[9]],
		"/(public)/(legal)/privacy": [54,[9,11]],
		"/(public)/(legal)/privacy/deletion": [55,[9,11]],
		"/(public)/search": [64,[9]],
		"/(public)/(legal)/terms": [56,[9,11]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';